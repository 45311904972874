<template>
    <div class="container mt-3">
        <div v-for="(item, index) in items" :key="index" class="d-flex align-items-center mb-2">
            <input type="radio" 
                :id="'radio-' + index" 
                name="options" 
                :value="item.id" 
                v-model="selectedItem"
                class="me-2" 
                />

            <label :for="'radio-' + index" class="d-flex align-items-center cursor-pointer"
                @click="handleLabelClick(index)">
                <img :src="item.path" alt="Imagen 1" class="img-thumbnail me-2" width="100" height="100">
                <img :src="item.thumbnail" alt="Imagen 2" class="img-thumbnail me-2" width="100" height="100">
                <span class="me-2">{{ item.label }}</span>
            </label>

            <button class="btn btn-sm btn-primary me-2" @click="editItem(index)">
                ✏️
            </button>
            <button class="btn btn-sm btn-danger" @click="deleteItem(index)">
                ❌
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {

    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedItem: null,
            items: this.data,
            items333: [
                { value: 'opcion1', label: 'Opción 1', image1: 'https://via.placeholder.com/50', image2: 'https://via.placeholder.com/50' },
                { value: 'opcion2', label: 'Opción 2', image1: 'https://via.placeholder.com/50', image2: 'https://via.placeholder.com/50' },
                { value: 'opcion3', label: 'Opción 3', image1: 'https://via.placeholder.com/50', image2: 'https://via.placeholder.com/50' }
            ],
        };
    },
    mounted() {
    },
    watch: {
        // Si los items cambian, seleccionar el primero con meta === 1
        items: {
            immediate: true, // Ejecutar al montar
            handler(newItems) {
                const selected = newItems.find(item => item.meta === 1);
                if (selected) {
                    this.selectedItem = selected.id;
                }
            }
        }
    },
    methods: {
        handleLabelClick(index) {
            console.log(`Label de ${this.items[index].label} clickeado`);
            console.log(this.items[index]);
            console.log(this.items[index].id);


            const url = this.appDomainApi + "shops/product/image/update/" + this.items[index].product_id;

            axios.post(url, this.items[index])
                .then(response => {
                    console.log(response.data);
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        editItem(index) {
            alert(`Editar: ${this.items[index].label}`);
        },
        deleteItem(index) {

            console.log(this.items[index]);


            const url = this.appDomainApi + "shops/product/image/related/" + this.items[index].product_id;

            // const data = {
            //     'id':image_id,
            // }

            axios.post(url, this.items[index])
                .then(response => {
                    console.log(response.data);
                    this.items = [];
                    this.items = response.data;
                    this.items.splice(index, 1);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.img-thumbnail {
  border-radius: 5px;
}
</style>